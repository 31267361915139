@media screen and (max-width: 600px) {
    .dashboard{
        overflow-x: scroll;
    }
    
}
.DailySummary{
    display: block;
    text-align: left;
    padding: 15px;

}
.DailyDetail[open] .DailySummary{
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    background-color: #008cff;
    color: white;
}