.headerAdmin{
    position: absolute;
    color: white;
    margin: auto;
    top: 10px;
    border-radius: 40px;
    align-items: center;
    width: 80%;
    margin: auto;
    left: 10%;
    z-index: 1000;
    background: #6e6e6e80 !important;
    a{
        color: rgb(255, 255, 255) !important;
    }
}
.headerAdmin nav{
    justify-content: center;
    padding: 10px;
    color: white;
    align-items: center;
}

.headerAdmin nav ul{
    display: flex;
    justify-content: center;
    gap: 20px;
    list-style: none;

}
