.detail{
    background-image: url(https://images7.alphacoders.com/897/897920.jpg );
    background-position: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5px;
}
.detail__section{

    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    color: white;
    margin-top: 40px;

}
h3{
    text-align: left;
}