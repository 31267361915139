.add{
    
    background-image: url(https://images7.alphacoders.com/897/897920.jpg );
    background-position: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 5px 5px 0 ;
}
.addform{
    margin:40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;

}
.addform input{
    width: 100%;
    padding: 6px 8px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #ffffffcc;
}
.addform label{
    color: white;

}
.addform button{
    width: 100%;
    padding: 6px 8px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #008cff;
    color: white;
    cursor: pointer;
}
.links{
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 10px;
    max-width: 500px;
    margin: 10px    auto;
}
.copy_component{
    width: 100%;
    margin: auto;
    display: flex;
    background-color: #f2ecec;
    border-radius: 5px;
    color: black;
    gap: 5px;
    padding: 5px;
    cursor: pointer;
    p{
        width: 80%;
        margin: auto;
        overflow-x: scroll;
        padding: 6px 8px;
        border: none;
        outline: none;
        border-radius: 5px;
    }
    button{
        width: 20%;
        height: 100%;
        margin: auto;
        padding: 6px 8px;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: #008cff;
        color: white;
        cursor: pointer;
        font-size: small;
    }
}