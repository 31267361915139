.workerHome{
    
    background-image: url(https://images7.alphacoders.com/897/897920.jpg );
    background-position: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 5px 5px 0 ;
}
.workerContent{
    margin:40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;

}