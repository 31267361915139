.QrCodeMain{
    background-image: url(https://images7.alphacoders.com/897/897920.jpg );
    background-position: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 10px 5px;
    justify-content: center;
    color: white;
    .content{
        margin:40px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        gap: 8px;
        background-color: rgba(0, 0, 0, 0.7);
        padding:15px  15px;
        border-radius: 10px;
        max-width: 400px;
        padding: 10px;
    }
    .info{
        width: 100%;
        text-align-last: left !important;
    }
    .links{
        .link{
            padding: 8px 6px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        i{
            font-size: 18px;
        }
    } 
        a{
                text-decoration: none;
                color: rgb(255, 255, 255);
                font-weight:600 ;
        }
        .text-Whatsapp{
            color:	#25D366;
        }
        .m-0{
            margin: 0;
        }
        h5{
            margin: 0;
        }
}